import type { FC } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import { PageContainer, ProLayout, ProCard } from "@ant-design/pro-components";
import {
  GithubFilled,
  InfoCircleFilled,
  QuestionCircleFilled,
  SmileFilled,
  CrownFilled,
  AppstoreFilled,
  SettingFilled,
  HddFilled,
  LockFilled,
  SignalFilled,
} from "@ant-design/icons";
import { App } from "antd";
import { Welcome } from "./routes";
import { Apps } from "./routes/app";
import SignIn from "./routes/signin";

const urlPath = (): string => {
  return window.location.hash.substring(1) || "/";
};

interface DProps {
  route: {
    path: string;
    routes: any[];
  };
  location: {
    pathname: string;
  };
  appList: any[];
}

const defaultProps = (path: string): DProps => {
  return {
    route: {
      path: path,
      routes: [
        {
          path: "/",
          name: "欢迎",
          icon: <SmileFilled />,
        },
        {
          path: "/apps",
          name: "应用",
          icon: <AppstoreFilled />,
        },
        {
          name: "系统设置",
          icon: <SettingFilled />,
          path: "/settings",
          routes: [
            {
              path: "/settings/groups",
              name: "分组设置",
              icon: <CrownFilled />,
            },
            {
              path: "/settings/users",
              name: "用户设置",
              icon: <HddFilled />,
            },
            {
              path: "/settings/secrets",
              name: "系统密钥",
              icon: <LockFilled />,
            },
          ],
        },
        {
          path: "/status",
          name: "服务器状态",
          icon: <SignalFilled />,
        },
      ],
    },
    location: {
      pathname: path,
    },
    appList: [
      {
        icon: "https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg",
        title: "Ant Design",
        desc: "杭州市较知名的 UI 设计语言",
        url: "https://ant.design",
      },
      {
        icon: "https://gw.alipayobjects.com/zos/antfincdn/FLrTNDvlna/antv.png",
        title: "AntV",
        desc: "蚂蚁集团全新一代数据可视化解决方案",
        url: "https://antv.vision/",
        target: "_blank",
      },
      {
        icon: "https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg",
        title: "Pro Components",
        desc: "专业级 UI 组件库",
        url: "https://procomponents.ant.design/",
      },
      {
        icon: "https://img.alicdn.com/tfs/TB1zomHwxv1gK0jSZFFXXb0sXXa-200-200.png",
        title: "umi",
        desc: "插件化的企业级前端应用框架。",
        url: "https://umijs.org/zh-CN/docs",
      },

      {
        icon: "https://gw.alipayobjects.com/zos/bmw-prod/8a74c1d3-16f3-4719-be63-15e467a68a24/km0cv8vn_w500_h500.png",
        title: "qiankun",
        desc: "可能是你见过最完善的微前端解决方案🧐",
        url: "https://qiankun.umijs.org/",
      },
      {
        icon: "https://gw.alipayobjects.com/zos/rmsportal/XuVpGqBFxXplzvLjJBZB.svg",
        title: "语雀",
        desc: "知识创作与分享工具",
        url: "https://www.yuque.com/",
      },
      {
        icon: "https://gw.alipayobjects.com/zos/rmsportal/LFooOLwmxGLsltmUjTAP.svg",
        title: "Kitchen ",
        desc: "Sketch 工具集",
        url: "https://kitchen.alipay.com/",
      },
      {
        icon: "https://gw.alipayobjects.com/zos/bmw-prod/d3e3eb39-1cd7-4aa5-827c-877deced6b7e/lalxt4g3_w256_h256.png",
        title: "dumi",
        desc: "为组件开发场景而生的文档工具",
        url: "https://d.umijs.org/zh-CN",
      },
    ],
  };
};

interface OLProps {
  element: FC<{}>;
  defaultProps: DProps;
}

const OuterLayer: FC<OLProps> = (props: OLProps) => {
  return (
    <div id="index-pro-layout" style={{ height: "100vh" }}>
      <ProLayout
        title="XTS"
        siderWidth={256}
        bgLayoutImgList={[
          {
            src: "https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png",
            left: 85,
            bottom: 100,
            height: "303px",
          },
          {
            src: "https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png",
            bottom: -68,
            right: -45,
            height: "303px",
          },
          {
            src: "https://img.alicdn.com/imgextra/i3/O1CN018NxReL1shX85Yz6Cx_!!6000000005798-2-tps-884-496.png",
            bottom: 0,
            left: 0,
            width: "331px",
          },
        ]}
        {...props.defaultProps}
        location={{
          pathname: urlPath(),
        }}
        avatarProps={{
          src: "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
          title: "七妮妮",
          size: "small",
        }}
        actionsRender={(props) => {
          if (props.isMobile) return [];
          return [
            <InfoCircleFilled key="InfoCircleFilled" />,
            <QuestionCircleFilled key="QuestionCircleFilled" />,
            <GithubFilled key="GithubFilled" />,
          ];
        }}
        menuItemRender={(item, dom) => (
          <Link to={item.path as string}>{dom}</Link>
        )}
      >
        <PageContainer>
          <ProCard
            style={{
              height: "100vh",
              minHeight: 800,
            }}
          >
            <props.element />
          </ProCard>
        </PageContainer>
      </ProLayout>
    </div>
  );
};

const sp2: FC = () => {
  return <div>sub-page2</div>;
};

const sp3: FC = () => {
  return <div>sub-page3</div>;
};

const sp4: FC = () => {
  return <div>sub-page4</div>;
};

const sp5: FC = () => {
  return <div>sub-page5</div>;
};

const sp6: FC = () => {
  return <div>sub-page6</div>;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <OuterLayer element={Welcome} defaultProps={defaultProps("/")} />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/apps",
    element: <OuterLayer element={Apps} defaultProps={defaultProps("/apps")} />,
  },
  {
    path: "/settings",
    element: (
      <OuterLayer element={sp2} defaultProps={defaultProps("/settings")} />
    ),
  },
  {
    path: "/settings/groups",
    element: (
      <OuterLayer
        element={sp3}
        defaultProps={defaultProps("/settings/groups")}
      />
    ),
  },
  {
    path: "/settings/users",
    element: (
      <OuterLayer
        element={sp4}
        defaultProps={defaultProps("/settings/users")}
      />
    ),
  },
  {
    path: "/settings/secrets",
    element: (
      <OuterLayer
        element={sp5}
        defaultProps={defaultProps("/settings/secrets")}
      />
    ),
  },
  {
    path: "/status",
    element: (
      <OuterLayer element={sp6} defaultProps={defaultProps("/status")} />
    ),
  },
]);

const root = createRoot(document.getElementById("app") as HTMLElement);

root.render(
  <App>
    <RouterProvider router={router} />
  </App>
);
