import type { FC } from "react";
import { Link } from "react-router-dom";
import { ProCard } from "@ant-design/pro-components";
import { FileOutlined, UserOutlined, ApiFilled } from "@ant-design/icons";
import { Row, Col } from "antd";

interface SingleApp {
  name: string; // example: 学生列表
  icon: any; // example: <UserOutlined />
  pkg: string; // example: student-list
  description?: string;
}

const getPath = (app: SingleApp): string => {
  return `/app/${app.pkg}`;
};

const appList: SingleApp[] = [
  {
    name: "学生管理",
    icon: <UserOutlined />,
    pkg: "student",
    description: "学生管理",
  },
  {
    name: "电子存档管理",
    icon: <FileOutlined />,
    pkg: "archive",
    description: "电子存档管理",
  },
  {
    name: "系统工作流",
    icon: <ApiFilled />,
    pkg: "workflow",
    description: "系统工作流",
  },
];

const Apps: FC = () => {
  return (
    <>
      <ProCard>
        <Row>
          {appList.map((value, index) => (
            <>
              <Col span={5} key={index}>
                <Link to={getPath(value)}>
                  <ProCard
                    key={value.pkg}
                    title={<b>{value.name}</b>}
                    extra={value.icon}
                    bordered
                    hoverable
                    layout="default"
                    size="small"
                    headerBordered
                  >
                    {value.description}
                  </ProCard>
                </Link>
              </Col>
              <Col span={1}></Col>
            </>
          ))}
        </Row>
      </ProCard>
    </>
  );
};

export { Apps };
