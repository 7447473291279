import type { FC } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  LoginFormPage,
  ProFormCheckbox,
  ProFormText,
} from "@ant-design/pro-components";
import { Tabs, Modal } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";

type LoginType = "account";

const SignIn: FC = () => {
  const [loginType, setLoginType] = useState<LoginType>("account");
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "calc(100vh - 48px)",
        margin: -24,
      }}
    >
      <LoginFormPage
        backgroundImageUrl="https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png"
        title="XTS"
        subTitle="中队纪检部行规分统计追踪平台"
        onFinish={async (values) => {
            Modal.success({
                content: (
                    <>
                        <div>用户名：{values.username}</div>
                        <div>密码：{values.password}</div>
                        <div>记住我：{values.rememberMe ? "是" : "否"}</div>
                    </>
                ),
            });
        }}
      >
        <Tabs
          centered
          activeKey={loginType}
          onChange={(activeKey) => setLoginType(activeKey as LoginType)}
          items={[
            {
              key: "account",
              label: "账号密码登录",
            },
          ]}
        />
        {loginType === "account" && (
          <>
            <ProFormText
              name="username"
              fieldProps={{
                size: "large",
                prefix: <UserOutlined className={"prefixIcon"} />,
              }}
              placeholder={"请输入用户名"}
              rules={[
                {
                  required: true,
                  message: "请输入用户名!",
                },
              ]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: "large",
                prefix: <LockOutlined className={"prefixIcon"} />,
              }}
              placeholder={"请输入密码"}
              rules={[
                {
                  required: true,
                  message: "请输入密码！",
                },
              ]}
            />
          </>
        )}
        <div
          style={{
            marginBlockEnd: 24,
          }}
        >
          <ProFormCheckbox noStyle name="rememberMe">
            记住我（7天）
          </ProFormCheckbox>
          <Link
            to="/register"
            style={{
              float: "right",
            }}
          >
            注册
          </Link>
        </div>
      </LoginFormPage>
    </div>
  );
};

export default SignIn;
